.main_wrap{
  padding: 65px 4%;
  background-color:#eceff1;
}
img{
  max-width: 100%;
  height: auto;
}
.app_link{
  text-decoration: none;
}
.card_wrap{
  margin-bottom: 15px;
}
.select_wrap{
  margin-bottom: 30px;
}
.card_wrap p{
  margin: 0 0 1em 0;
  padding: 0;
}
.card_wrap h3{
  margin: 0 0 0.5em; 
}
.price{
  color:red;
  font-weight: bold;
}
.text-white{
  color:#fff;
}
.ai_score{
  font-size:80px;
  text-align: center;
  margin: 0;
  padding: 0;
  color:red;
}